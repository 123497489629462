import React, { useEffect } from "react";
import { ABOUT_TITLE } from "constants/siteData";
import "./About.css";
import bioPic from "static/img/working.jpg";

const About = () => {
  useEffect(() => {
    document.title = ABOUT_TITLE;
  });

  return (
    <div className="segment-container  l-padded">
      <div className="container">
        <div className="columns">
          <div className="column is-four-fifths">
            <h1 className="is-primary-color">About</h1>
            <p>
              Certified Electric has been serving the Greater Sacramento Area
              for over 25 years, proudly located in Lincoln California. We are a
              privately owned electrical company, licensed and bonded by the
              state of California (CA Lic #910530). We offer a wide variety of
              electrical services at a great price. We are well rounded in all
              areas of electrical apparatus installs. We specialize in
              commercial/industrial electrical projects and design, 3 phase
              electrical panels from 1300 amps to 5000 amps, motor control
              wiring installation for factories, and also commercial building
              add ons.
            </p>
          </div>
          <div className="column">
            <div className="bio-card box grey-background">
              <img src={bioPic} />
              <h2 className="is-white">Roland Benavidez</h2>
              <h3 className="is-light-grey">
                Certified Journeyman Electrician
              </h3>
            </div>
          </div>
        </div>
        <section className="section">
          <div className="columns">
            <div className="column">
              <h2 className="is-primary-color giant-text ">What We Offer</h2>
              <div className="content">
                <ul>
                  <li>
                    <strong className="is-white">
                      24hr Emergency Service Calls
                    </strong>
                  </li>
                  <li>Three Phase Breaker Replacement</li>
                  <li>Three Phase Wiring Testing</li>
                  <li>Electrical Motor Installation</li>
                  <li>Main Panel Upgrades</li>
                  <li>Convier Belt Motor Replacement</li>
                  <li>Building/Office Electrical Plans</li>
                  <li>Motor Control Wiring</li>
                  <li>Add Wiring to Existing Circuit</li>
                  <li>Add Switch Plugs to Office</li>
                  <li>New Construction and Remodels</li>
                  <li>Generator Installation</li>
                  <li>Lighting Installation/Remodelling</li>
                  <li>Wiring Repair</li>
                  <li>Wire Locating</li>
                </ul>
              </div>
            </div>
            <div className="column">
              <h2 className="is-primary-color giant-text ">Areas of Service</h2>
              <div className="content">
                <ul>
                  <li>Lincoln</li>
                  <li>Roseville</li>
                  <li>Rocklin</li>
                  <li>Yuba City</li>
                  <li>Wheatland</li>
                  <li>Woodland</li>
                  <li>All Valley Facilities Areas</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default About;
