import React from "react";

import bioImage from "../../static/img/working.jpg";

import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="columns">
          <div className="column">
            <h2 className="title-text">Contact</h2>

            <p>
              Phone:{" "}
              <a className="is-primary-color" href="tel:5307180568">
                (530) 718-0568
              </a>
            </p>
            <p>
              Email:{" "}
              <a
                className="is-primary-color"
                href=" mailto:certifiedelectric@yahoo.com"
              >
                certifiedelectric@yahoo.com
              </a>
            </p>
          </div>

          <div className="column">
            <h2 className="title-text">License Info</h2>
            CA Lic #: <span className="is-primary-color">910530</span>
          </div>
        </div>
        <div className="bottom-info">
          © 2020 Certified Electric |
          <a className="is-primary-color"> Privacy Policy</a>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
