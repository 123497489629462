import React, { useState } from "react";
import "./NavBar.css";
import logo from "static/img/logo.png";


const NavBar = () => {
  const hamburgerState = useState(false);
  const hamburgerValue = hamburgerState[0];
  const setHamburger = hamburgerState[1];

  console.log(hamburgerValue);
  return (
    <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item hvr-grow" href="/">
          <img className="logo" src={logo} alt="Circular logo" />
        </a>

        <a
          onClick={() => setHamburger(!hamburgerValue)}
          role="button"
          className={hamburgerValue ? "navbar-burger burger is-active" : "navbar-burger burger"}
          aria-label="menu"
          aria-expanded={hamburgerValue}
          data-target="navMenu"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div className={hamburgerValue ? "navbar-menu is-active" : "navbar-menu"} >
        <div className="navbar-end">
          <a href="/residential" className="navbar-item ">
            RESIDENTIAL
          </a>

          <a href="/commercial" className="navbar-item">
            COMMERCIAL
          </a>
          <a href="/industrial" className="navbar-item">
            INDUSTRIAL
          </a >
          <a href="/about" className="navbar-item">
            ABOUT
          </a >
          <div className="call-info is-center">
            <p>
              <a href="tel:5307180568" className="is-primary-color">(530) 718-0568</a>
            </p>
          </div>
        </div >
      </div >
    </nav >
  );
}



export default NavBar;
