import React, { useEffect } from 'react';
import { PAGE_NOT_FOUND_TITLE } from "constants/siteData";

const PageNotFound = () => {

    useEffect(() => { document.title = PAGE_NOT_FOUND_TITLE });

    return <div>Page not found</div>
}

export default PageNotFound;