import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import "bulma/css/bulma.css";
import "@brainhubeu/react-carousel/lib/style.css";
import Layout from "common/Layout";
import PageNotFound from "routes/PageNotFound";
import Home from "routes/Home";
import About from "routes/About";
import ContactUs from "routes/ContactUs";
import Residential from "routes/Residential";
import Commercial from "routes/Commercial";
import Industrial from "routes/Industrial";

const App = () => (
  <div className="app">
    <Layout>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/residential" component={Residential} />
          <Route exact path="/commercial" component={Commercial} />
          <Route exact path="/industrial" component={Industrial} />
          <Route component={PageNotFound} />
        </Switch>
      </BrowserRouter>
    </Layout>
  </div>
);

export default App;
