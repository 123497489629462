import React, { useEffect } from "react";
import { COMMERCIAL_TITLE } from "constants/siteData";

import Carousel from "@brainhubeu/react-carousel";

import ind_1 from "static/img/industrial/industrial_1.jpg";
import ind_2 from "static/img/industrial/industrial_2.jpg";
import ind_3 from "static/img/industrial/industrial_3.jpg";
import ind_4 from "static/img/industrial/industrial_4.jpg";

const Commercial = () => {
  useEffect(() => {
    document.title = COMMERCIAL_TITLE;
  });

  return (
    <div className="segment-container  l-padded">
      <div className="container">
        <div className="columns">
          <div className="column ">
            <h1 className="is-primary-color">Commercial</h1>
            <p>
              Whether it's handling an electrical emergency or adding on to your
              existing electrical system, trust Certified Electric to keep your
              business running.
            </p>
          </div>

          <div className="column">
            <h2 className="is-primary-color giant-text ">What We Offer</h2>
            <div className="content">
              <ul>
                <li>
                  <strong className="is-white">
                    24hr Emergency Service Calls
                  </strong>
                </li>
                <li>Three Phase Breaker Replacement</li>
                <li>Three Phase Wiring Testing</li>
                <li>Electrical Motor Installation</li>
                <li>Main Panel Upgrades</li>
                <li>Convier Belt Motor Replacement</li>
                <li>Building/Office Electrical Plans</li>
                <li>Motor Control Wiring</li>
                <li>Add Wiring to Existing Circuit</li>
                <li>Add Switch Plugs to Office</li>
                <li>New Construction and Remodels</li>
                <li>Generator Installation</li>
                <li>Lighting Installation/Remodelling</li>
                <li>Wiring Repair</li>
                <li>Wire Locating</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="capped-height">
        <Carousel
          animationSpeed={500}
          autoPlay={3000}
          stopAutoPlayOnHover
          arrows
        >
          <img className="capped-height" src={ind_1} />
          <img className="capped-height" src={ind_2} />
          <img className="capped-height" src={ind_3} />
          <img className="capped-height" src={ind_4} />
        </Carousel>
      </div>
    </div>
  );
};

export default Commercial;
