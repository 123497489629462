import React, { useEffect } from "react";
import "./Residential.css";
import { RESIDENTIAL_TITLE } from "constants/siteData";
import Carousel from "@brainhubeu/react-carousel";

import res_1 from "static/img/residential/residential_1.jpg";
import res_2 from "static/img/residential/residential_2.jpg";
import res_3 from "static/img/residential/residential_3.jpg";
import res_4 from "static/img/residential/residential_4.jpg";
import res_5 from "static/img/residential/residential_5.jpg";
import res_6 from "static/img/residential/residential_6.jpg";
import res_7 from "static/img/residential/residential_7.jpg";
import res_8 from "static/img/residential/residential_8.jpg";
import res_9 from "static/img/residential/residential_9.jpg";
import res_10 from "static/img/residential/residential_10.jpg";
import res_11 from "static/img/residential/residential_11.jpg";
import res_12 from "static/img/residential/residential_12.jpg";
import res_13 from "static/img/residential/residential_13.jpg";
import res_14 from "static/img/residential/residential_14.jpg";

const Residential = () => {
  useEffect(() => {
    document.title = RESIDENTIAL_TITLE;
  });

  return (
    <div className="segment-container  l-padded">
      <div className="container">
        <div className="columns">
          <div className="column">
            <h1 className="is-primary-color">Residential</h1>
            <p>
              For all of your home electrical needs, Certified Electric has you
              covered. From installation to maintenance, we've got the
              experience.
            </p>
          </div>
          <div className="column">
            <h2 className="is-primary-color giant-text ">What We Offer</h2>
            <div className="content">
              <ul>
                <li>
                  <strong className="is-white">
                    24hr Emergency Service Calls
                  </strong>
                </li>
                <li>Electrical Panel Upgrade</li>
                <li>Well Water Pump Installation</li>
                <li>Generator Installation</li>
                <li>Lighting Installation/Remodelling</li>
                <li>New Construction/Remodel Wiring</li>
                <li>Wiring Repair</li>
                <li>Wire Locating</li>
                <li>Single Phase Electrical</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="capped-height">
          <Carousel
            animationSpeed={500}
            autoPlay={3000}
            stopAutoPlayOnHover
            arrows
          >
            <img className="capped-height" src={res_1} />
            <img className="capped-height" src={res_2} />
            <img className="capped-height" src={res_3} />
            <img className="capped-height" src={res_4} />
            <img className="capped-height" src={res_5} />
            <img className="capped-height" src={res_6} />
            <img className="capped-height" src={res_7} />
            <img className="capped-height" src={res_8} />
            <img className="capped-height" src={res_9} />
            <img className="capped-height" src={res_10} />
            <img className="capped-height" src={res_11} />
            <img className="capped-height" src={res_12} />
            <img className="capped-height" src={res_13} />
            <img className="capped-height" src={res_14} />

          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Residential;
